@import "vendors/swiper/swiper-bundle.min.scss";
@import "mixins.scss";

$FRAME_WIDTH_WIDE: 2400px;
$FRAME_HEIGHT_WIDE: 690px;
$FRAME_WIDTH_DESKTOP: 1920px;
$FRAME_HEIGHT_DESKTOP: 700px;
$FRAME_WIDTH_MOBILE: 1080px;
$FRAME_HEIGHT_MOBILE: 900px;

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #efeeec;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.boards-carrousel {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.ha-hidden {
  display: none;
}

.ha-fab-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
}

.ha-fab-close {
  fill: #1e3050;
  cursor: pointer;
  width: 16px;
  height: 16px;
  right: 8px;
  top: 8px;
  position: absolute;
}

.ha-fab {
  background-color: #fa8600;
  color: white;
  height: 52px;
  width: 52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 7px 8px -5px rgba(0, 0, 0, 0.2),
      0px 12px 17px 2px rgba(0, 0, 0, 0.14),
      0px 5px 22px 4px rgba(0, 0, 0, 0.12);

    svg {
      transform: rotate(-45deg);
    }
  }

  svg {
    height: 24px;
    width: 24px;
    fill: white;
    transition: all 0.2s ease-in-out;
  }
}

.ha-fab-content {
  background-color: white;
  position: absolute;
  bottom: 90px;
  right: 16px;
  border-radius: 12px;
  transition: all 0.1s ease-out;
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12));

  .ha-fab-arrow {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 14px;
    bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: rotate(90deg);
      height: 26px;
      fill: white;
    }
  }
}

.ha-fab-content-hidden {
  opacity: 0;
  visibility: hidden;
  bottom: 80px;
}

.ha-config-wrapper {
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  min-width: 256px;

  h2 {
    margin: 0px;
    margin-bottom: 8px;
  }

  h3 {
    font-weight: 400;
  }
}

.ha-configs-title {
  margin-bottom: 0px;
}

.ha-config-title {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
}

.ha-config-select {
  display: inline-block;
}

.board-carrousel-carrousel-wrapper {
  display: flex;
  flex-direction: column;
  background: #efeeec;
  padding-bottom: 15px;

  @media (min-width: 768px) {
    padding-bottom: 30px;
  }

  @media (min-width: 768px) {
    overflow: hidden;
  }
}

.board-carrousel-swiper {
  width: 100%;
}

.board-carrousel-swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #efeeec;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.board-carrousel-canvasWrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;

  @media (min-width: 100px) {
    padding-bottom: 50px;
  }

  @media (min-width: 300px) {
    padding-bottom: 30px;
  }

  @media (min-width: 400px) {
    padding-bottom: 30px;
  }

  @media (min-width: 450px) {
    padding-bottom: 20px;
  }

  @media (min-width: 500px) {
    padding-bottom: 15px;
  }

  @media (min-width: 600px) {
    padding-bottom: 10px;
  }

  @media (min-width: 768px) {
    padding-bottom: 20px;
  }

  @media (min-width: 900px) {
    padding-bottom: 15px;
  }

  @media (min-width: 1000px) {
    padding-bottom: 10px;
  }

  @media (min-width: 1100px) {
    padding-bottom: 5px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 0;
  }
}

.board-carrousel-swiper-button-next,
.board-carrousel-swiper-button-prev {
  background-color: transparent;
  bottom: 0px;
  top: 0px;
  height: 100%;

  &::after {
    content: "";
    font-size: 0px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.board-carrousel-swiper-button-next {
  right: 0;
  width: 10%;
}

.board-carrousel-swiper-button-prev {
  left: 0;
  width: 20%;
}

.board-carrousel-swiper-pagination-horizontal {
  bottom: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.board-carrousel-swiper-pagination-bullet {
  background-color: transparent;
  opacity: 1;
  transition: all 0.5s;
  display: flex;
  max-width: 30px;
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 0 !important;
  margin: 5px 0px;

  &::after {
    content: " ";
    background: #d4d2ca;
    width: 100%;
    height: 5px;
  }

  &:hover::after {
    background: rgba(250, 133, 0, 0.5);
  }
}

.board-carrousel-swiper-pagination-bullet-active {
  background: transparent;

  &::after {
    background-color: #fa8600;
  }

  &:hover::after {
    background-color: #fa8600 !important;
  }
}

.board-carrousel-overlayWrapper {
  overflow: hidden;
  position: relative;
  height: auto;
  transition: all 0.2s;
  box-sizing: content-box !important;

  @media (min-width: 768px) {
    max-width: 1160px;
    width: 100%;
    margin: auto;
  }

  padding: 16px;

  @media (min-width: 200px) {
    padding: 36px;
  }

  @media (min-width: 481px) {
    padding: 40px;
  }

  @media (min-width: 768px) {
    padding: 60px;
  }

  padding-bottom: 20px !important;

  .board-carrousel-cta {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    a {
      box-sizing: border-box;
      text-decoration: none;
      text-transform: uppercase;
      padding: 8px 16px;
      padding-top: 10px;
      max-width: 110px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      border-radius: 9999px;
      font-family: "Avenir-Medium";
      transition: transform 0.25s, box-shadow 0.5s;
    }
  }

  p {
    margin: 0;
    transition: font-size 0.15s;
  }

  .board-carrousel-orange {
    color: #eb8d33;
  }

  .board-carrousel-series {
    color: #fa8600;
    font-size: 16px;
    margin-bottom: 8px;
    font-size: 18px;
    font-family: "Avenir-Medium";
  }

  .board-carrousel-series.limited-edition {
    color: #ed782f;
  }

  .board-carrousel-name {
    margin-bottom: 20px;
    font-size: 22px;
    color: #635d47;
    font-size: 36px;
    font-family: "Avenir-Medium";

    @include md {
      font-size: 40px;
    }
  }

  .board-carrousel-description {
    margin-bottom: 10px;
    font-size: 20px;
    color: #635d47;
    font-family: "Avenir";
    white-space: inherit;

    @media (min-width: 350px) {
      white-space: pre-line;
    }

    // @media (min-width: 600px) {
    //   white-space: inherit;
    // }

    @include sm {
      font-size: 22px;
    }
  }

  .board-carrousel-price {
    margin-bottom: 20px;
    font-size: 18px;
    color: #635d47;
    font-family: "Avenir";
  }

  .board-carrousel-cta1 {
    color: #ffffff;
    background: #fa8600;
  }

  .board-carrousel-cta2 {
    color: #635d47;
    border: 1px solid #635d47;
    background: transparent;
  }
}

/* Skeleton Styles */
.skeleton {
  background: linear-gradient(
    90deg,
    hsl(200, 15%, 85%),
    hsl(200, 15%, 92%),
    hsl(200, 15%, 85%)
  );
  background-size: 200% 100%;
  animation: skeleton-loading 2s infinite linear;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.board-carrousel-series.skeleton {
  height: 18px;
  width: 25%;
  margin-bottom: 20px;
}

.board-carrousel-name.skeleton {
  height: 40px;
  width: 300px;
  margin: 20px 0 20px 0;
}

.board-carrousel-description.skeleton {
  height: 20px;
  width: 30%;
  margin-bottom: 20px;
}

.board-carrousel-price.skeleton {
  height: 18px;
  width: 25%;
  margin-bottom: 20px;
}

.board-carrousel-cta1.skeleton,
.board-carrousel-cta2.skeleton {
  height: 40px;
  width: 110px;
  margin-right: 10px;
}

.board-carrousel-boardCanvas.skeleton {
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 1280px) {
    height: calc($FRAME_HEIGHT_WIDE * 100vw / $FRAME_WIDTH_WIDE);
  }
  @media (min-width: 768px) {
    height: calc($FRAME_HEIGHT_DESKTOP * 100vw / $FRAME_WIDTH_DESKTOP);
  }
  height: calc($FRAME_HEIGHT_MOBILE * 100vw / $FRAME_WIDTH_MOBILE);
}

.loaded {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
